@font-face {
  font-family: "NanumSquare";
  font-weight: 400;
  src: url("./font/NanumSquareR.otf");
  src: url("./font/NanumSquareR.otf") format("opentype"),
    url("./font/NanumSquareR.eot?#iefix") format("embedded-opentype"),
    url("./font/NanumSquareR.woff") format("woff"),
    url("./font/NanumSquareR.ttf") format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 700;
  src: url("./font/NanumSquareB.otf");
  src: url("./font/NanumSquareB.otf") format("opentype"),
    url("./font/NanumSquareB.eot?#iefix") format("embedded-opentype"),
    url("./font/NanumSquareB.woff") format("woff"),
    url("./font/NanumSquareB.ttf") format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: bold;
  src: url("./font/NanumSquareEB.otf");
  src: url("./font/NanumSquareEB.eot?#iefix") format("embedded-opentype"),
    url("./font/NanumSquareEB.woff") format("woff"),
    url("./font/NanumSquareEB.otf") format("opentype"),
    url("./font/NanumSquareEB.ttf") format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 300;
  src: url("./font/NanumSquareL.otf");
  src: url("./font/NanumSquareL.otf") format("opentype"),
    url("./font/NanumSquareL.eot?#iefix") format("embedded-opentype"),
    url("./font/NanumSquareL.woff") format("woff"),
    url("./font/NanumSquareL.ttf") format("truetype");
}
@font-face {
  font-family: "Dohyeon";
  src: url(./font/BMDOHYEON_ttf.ttf);
  src: url(./font/BMDOHYEON_otf.otf) format("opentype");
}
@font-face {
  font-family: "NotoSans";
  src: url(./font/NotoSansKRL.otf) format("opentype");
}

@font-face {
  font-family: "NotoSans";
  font-weight: bold;
  src: url(./font/NotoSansKRM.otf) format("opentype");
}

html {
  width: 100%;
  height: 100%;
  user-select: none;
}

body {
  font-family: "NanumSquare";
  font-size: calc(10px + 1vmin);
  color: black;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  flex: 1;
  user-select: none;
  -moz-user-select: none;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* image-rendering: -webkit-optimize-contrast !important; */
}

#root {
  flex: 1;
  width: 100%;
  height: 100%;
}

img {
  -webkit-user-drag: none;
}


