.example {
  display: -ms-grid;
  display: grid;
  width: 80%;
  margin-left: 2%;
  height: 80%;
  -ms-grid-columns: 1fr 10px 1fr;
  grid-template-columns: repeat(2, 1fr);
  -ms-grid-rows: 1fr 10px 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px 10px;
}
.example > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.example > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.example > *:nth-child(3) {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}
.example > *:nth-child(4) {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
}
/* .example > *:nth-child(n + 1):nth-child(-n + 4) {
  display: block;
  word-break: keep-all;
  word-wrap: break-word;
  white-space: unset;
} */
