$fontUrl: "./font/NanumSquare";
$fontName: "NanumSquare";
@font-face {
  font-family: $fontName;
  font-weight: 400;
  src: url($fontUrl + "R.otf");
  src: url($fontUrl + "R.otf") format("opentype"),
    url($fontUrl + "R.eot?#iefix") format("embedded-opentype"),
    url($fontUrl + "R.woff") format("woff"),
    url($fontUrl + "R.ttf") format("truetype");
}
@font-face {
  font-family: $fontName;
  font-weight: 700;
  src: url($fontUrl + "B.otf");
  src: url($fontUrl + "B.otf") format("opentype"),
    url($fontUrl + "B.eot?#iefix") format("embedded-opentype"),
    url($fontUrl + "B.woff") format("woff"),
    url($fontUrl + "B.ttf") format("truetype");
}
@font-face {
  font-family: $fontName;
  font-weight: bold;
  src: url($fontUrl + "EB.otf");
  src: url($fontUrl + "EB.eot?#iefix") format("embedded-opentype"),
    url($fontUrl + "EB.woff") format("woff"),
    url($fontUrl + "EB.otf") format("opentype"),
    url($fontUrl + "EB.ttf") format("truetype");
}
@font-face {
  font-family: $fontName;
  font-weight: 300;
  src: url($fontUrl + "L.otf");
  src: url($fontUrl + "L.otf") format("opentype"),
    url($fontUrl + "L.eot?#iefix") format("embedded-opentype"),
    url($fontUrl + "L.woff") format("woff"),
    url($fontUrl + "L.ttf") format("truetype");
}
@font-face {
  font-family: "Dohyeon";
  src: url(./font/BMDOHYEON_ttf.ttf);
  src: url(./font/BMDOHYEON_otf.otf) format("opentype");
}

html {
  width: 100%;
  height: 100%;
  user-select: none;
}
body {
  font-family: "NanumSquare";
  font-size: calc(10px + 1vmin);
  color: black;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  -moz-user-select: none;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* image-rendering: -webkit-optimize-contrast !important; */
}
img {
  -webkit-user-drag: none;
}
